<template>
  <section class="hero is-primary is-fullheight login-principal-container">
    <!-- Hero head: will stick at the top -->
    <div class="hero-head">
      <nav class="navbar">
        <div class="container">
          <div class="navbar-brand">
            <a class="navbar-item" href="https://www.coconutcontrol.com/">
              <img src="@/assets/img/logo-final.png" />
            </a>
            <span class="navbar-burger burger" data-target="navbarMenuHeroA">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
          <div id="navbarMenuHeroA" class="navbar-menu">
            <div class="navbar-end">
              <a class="navbar-item is-active" @click="openLogin()">
                <b class="change-login-register-action">Iniciar sesión </b>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>

    <!-- Hero content: will be in the middle -->
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="columns is-centered absolut-center">
          <div class="column is-half login-form-container">
            <p class="title" data-testid="login-title">Validación de correo</p>
          </div>
          <div class="column is-half login-form-container"></div>
        </div>
      </div>
    </div>

    <!-- Hero footer: will stick at the bottom -->
    <div class="hero-foot">
      <nav class="tabs">
        <div class="container">
          <ul>
            <li class="is-active"><a></a></li>
            <li><a>Punto de venta</a></li>
            <li><a>Control de inventario</a></li>
            <li><a>Venta de productos</a></li>
            <li><a>Administración de sucursales</a></li>
            <li><a>Control de pedidos</a></li>
          </ul>
        </div>
      </nav>
    </div>
  </section>
</template>

<script>
import SecureLS from "secure-ls";
// @ is an alias to /src
export default {
  name: "ValidateEmail",
  components: {},
  data() {
    return {
      emailValidation: {
        token: "",
      },
      error: {
        token: false,
      },
    };
  },
  mounted() {
    this.emailValidation.token = this.$route.query.token;
    this.validateEmail();
  },
  methods: {
    openHome() {
      this.$router.push({ path: "/Dashboards/Initial" }).catch(() => {});
    },
    openLogin() {
      this.$router.push({ path: "/Login" }).catch(() => {});
    },
    async validateEmail() {
      if (!this.emailValidation.token) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Token no valido",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
      const response = await this.$store.dispatch(
        "VALIDATEEMAIL",
        this.emailValidation
      );
      if (response === "Success") {
        this.emailValidation = {
          token: "",
        };
        const ls = new SecureLS({ isCompression: false });
        const storageInformation = ls.get(
          this.userInformation.companyMainName + "ERPFCBUser"
        );
        if (storageInformation) {
          const currentUserInformation = JSON.parse(storageInformation);
          ls.set(
            this.userInformation.companyMainName + "ERPFCBUser",
            JSON.stringify({ ...currentUserInformation, validatedEmail: true })
          );
          this.openHome();
        } else {
          this.openLogin();
        }
      } else {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al validar el correo",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {
    userInformation() {
      return this.$store.getters.USER;
    },
  },
};
</script>

<style scoped>
@import "../style/Login.css";
@import "../style/Global.css";
</style>
